import { useAxios } from '../../axiosUtil'
import { getLabPaper, getLabPatent, getLabPjt } from '../../platform/community'

const token = localStorage.getItem('token')
const tokenType = localStorage.getItem('tokenType')
const headerOpt = {
  method: 'POST',
  headers: {
    Authorization: `${tokenType} ${token}`
  }
}

const pjtUrl = '/v1/board/lab-project' // 프로젝트 저장 url
const paperUrl = '/v1/board/lab-paper' // 논문 저장 url
const patentUrl = '/v1/board/lab-patent' // 지재권 저장 url
const handoverUrl = '/v1/board/lab-tech-handover' // 산학협력 저장 url

// ******************** NITS 프로젝트 저장 함수  ********************
export async function insertPjtTable(pjtData, inputData) {
  // 과제 기준 연도 값 설정
  const pjtYear = new Date(pjtData.projectYear.toString())
  const SavePjtData = {
    labNo: Number(inputData.labNo),
    projectNumber: pjtData.projectNumber,
    projectTitleKr: pjtData.projectTitleKr,
    projectTitleEn: pjtData.projectTitleEn,
    projectManager: pjtData.projectManager,
    projectResearchers: pjtData.projectResearchers,
    projectGoal: pjtData.projectGoal,
    projectAbstract: pjtData.projectAbstract,
    projectEffect: pjtData.projectEffect,
    projectKeywordKr: pjtData.projectKeywordKr,
    projectKeywordEn: pjtData.projectKeywordEn,
    projectLeadAgency: pjtData.projectLeadAgency,
    projectOrderAgency: pjtData.projectOrderAgency,
    projectOrderAgencyCd: pjtData.projectOrderAgencyCd,
    projectResearchAgency: pjtData.projectResearchAgency,
    projectResearchAgencyCd: pjtData.projectResearchAgencyCd,
    projectBudgetProject: pjtData.projectBudgetProject,
    projectBudgetProjectCd: pjtData.projectBudgetProjectCd,
    projectBigprojectTitle: pjtData.projectBigprojectTitle,
    projectManageAgency: pjtData.projectManageAgency,
    projectManageAgencyCd: pjtData.projectManageAgencyCd,
    projectMinistry: pjtData.projectMinistry,
    projectMinistryCd: pjtData.projectMinistryCd,
    projectYear: pjtYear,
    projectThisYearStart: pjtData.projectThisYearStart,
    projectThisYearEnd: pjtData.projectThisYearEnd,
    projectPeriodTotalStart: pjtData.projectPeriodTotalStart,
    projectPeriodTotalEnd: pjtData.projectPeriodEnd,
    projectOrganizationPNumber: pjtData.projectOrganizationPNumber,
    projectScienceClassOldLarge: pjtData.projectScienceClassOldLarge,
    projectScienceClassOldMedium: pjtData.projectScienceClassOldMedium,
    projectScienceClassOldSmall: pjtData.projectScienceClassOldSmall,
    projectScienceClassNew1Large: pjtData.projectScienceClassNew1Large,
    projectScienceClassNew1Medium: pjtData.projectScienceClassNew1Medium,
    projectScienceClassNew1Small: pjtData.projectScienceClassNew1Small,
    projectScienceClassNew2Large: pjtData.projectScienceClassNew2Large,
    projectScienceClassNew2Medium: pjtData.projectScienceClassNew2Medium,
    projectScienceClassNew2Small: pjtData.projectScienceClassNew2Small,
    projectScienceClassNew3Large: pjtData.projectScienceClassNew3Large,
    projectScienceClassNew3Medium: pjtData.projectScienceClassNew3Medium,
    projectScienceClassNew3Small: pjtData.projectScienceClassNew3Small,
    projectMinistryScienceClassLarge: pjtData.projectMinistryScienceClassLarge,
    projectMinistryScienceClassMedium: pjtData.projectMinistryScienceClassMedium,
    projectMinistryScienceClassSmall: pjtData.projectMinistryScienceClassSmall,
    projectTempScienceClassLarge: pjtData.projectTempScienceClassLarge,
    projectTempScienceClassMedium: pjtData.projectTempScienceClassMedium,
    projectTempScienceClasSmall: pjtData.projectTempScienceClasSmall,
    projectPerformAgent: pjtData.projectPerformAgent,
    projectDevelopmentPhases: pjtData.projectDevelopmentPhases,
    projectTechnologyLifecycle: pjtData.projectTechnologyLifecycle,
    projectRegion: pjtData.projectRegion,
    projectEconomicSocialGoal: pjtData.projectEconomicSocialGoal,
    projectSixTechnology: pjtData.projectSixTechnology,
    projectApplyAreaFirst: pjtData.projectApplyAreaFirst,
    projectApplyAreaSecond: pjtData.projectApplyAreaSecond,
    projectApplyAreaThird: pjtData.projectApplyAreaThird,
    projectContinuousFlag: pjtData.projectContinuousFlag,
    projectPolicyProjectFlag: pjtData.projectPolicyProjectFlag,
    projectObtainOrganization: pjtData.projectObtainOrganization,
    projectGovernmentFunds: pjtData.projectGovernmentFunds,
    projectSbusinessFunds: pjtData.projectSbusinessFunds,
    projectTotalFunds: pjtData.projectTotalFunds,
    projectCorporateRegistrationNumber: pjtData.projectCorporateRegistrationNumber,
    projectSeriesProject: pjtData.projectSeriesProject,
    projectDetailSeriesProject: pjtData.projectDetailSeriesProject,
    projectStatus: inputData.projectStatus,
    projectPeriodStart: inputData.projectPeriodStart,
    projectPeriodEnd: inputData.projectPeriodEnd,
    projectAddTextKr: inputData.projectAddTextKr,
    projectAddTextEn: inputData.projectAddTextEn
  }

  const pjtDataOpt = { data: SavePjtData, ...headerOpt }
  const result = await useAxios(pjtUrl, pjtDataOpt)

  if (result.code === 'ER_DUP_ENTRY' && result.message.includes('board_lab_project.projectNumber_UNIQUE')) {
    const project = await getLabPjt({ projectNumber: SavePjtData.projectNumber, deletedFlag: 1 })

    if (project.items instanceof Array) {
      updateProjectTable({ ...SavePjtData, deletedFlag: '0' }, project.items[0].projectId)
    }
  }
}

export function updateProjectTable(projectData, projectId) {
  const url = `${pjtUrl}/${projectId}`
  return useAxios(url, {
    method: 'PUT',
    data: projectData
  })
}

// ******************** NITS 논문 저장 함수  ********************
export async function insertPaperTable(paperData, labNo, issueDate) {
  const savePaperData = []
  for (let i = 0; i < paperData.length; i++) {
    // 성과연도 값 넣어주기
    const paperYear = []
    paperYear.push(new Date(paperData[i].paperPubYear.toString()))

    savePaperData.push({
      labNo: labNo,
      paperResultID: paperData[i].paperResultID,
      paperResultTitle: paperData[i].paperResultTitle,
      paperJournalName: paperData[i].paperJournalName,
      paperIssnNumber: paperData[i].paperIssnNumber,
      paperAuthor: paperData[i].paperAuthor,
      paperAbstract: paperData[i].paperAbstract,
      paperKeywordKr: paperData[i].paperKeywordKr,
      paperKeywordEn: paperData[i].paperKeywordEn,
      paperPubYear: paperYear,
      paperProjectID: paperData[i].paperProjectID,
      paperPaperType: paperData[i].paperPaperType,
      paperSciType: paperData[i].paperSciType,
      paperNationType: paperData[i].paperNationType,
      paperJCType: paperData[i].paperJctType,
      paperSourceFlag: paperData[i].paperSourceFlag,
      paperKeywordFlag: paperData[i].paperKeywordFlag,
      paperProjectYear: paperData[i].paperProjectYear,
      paperBudgetProjectNumber: paperData[i].paperBudgetProjectNumber,
      paperBudgetProject: paperData[i].paperBudgetProject,
      paperMinistryName: paperData[i].paperMinistryName,
      paperProjectTitle: paperData[i].paperProjectTitle,
      paperPerformAgency: paperData[i].paperPerformAgency,
      paperPerformAgent: paperData[i].paperPerformAgent,
      paperSixTechnology: paperData[i].paperSixTechnology,
      paperTechnologyRoadMap: paperData[i].paperTechnologyRoadMap,
      paperScienceClass1: paperData[i].paperScienceClass1,
      paperScienceClass2: paperData[i].paperScienceClass2,
      paperScienceClass3: paperData[i].paperScienceClass3,
      paperIssueDate: issueDate
    })

    const paperDataOpt = { data: savePaperData[i], ...headerOpt }
    const result = await useAxios(paperUrl, paperDataOpt)

    if (result.code === 'ER_DUP_ENTRY' && result.message.includes('board_lab_paper.paperResultID_UNIQUE')) {
      const paper = await getLabPaper({ paperResultID: paperData[i].paperResultID, deletedFlag: 1 })

      if (paper.items instanceof Array) {
        updatePaperTable({ ...paperData[i], deletedFlag: '0' }, paper.items[0].paperId)
      }
    }
  }
}

export function updatePaperTable(paperData, paperId) {
  const url = `${paperUrl}/${paperId}`
  return useAxios(url, {
    method: 'PUT',
    data: paperData
  })
}

// ******************** NITS 지재권 저장 함수  ********************
export async function insertPatentTable(patentData, labNo) {
  // console.log(patentData)
  const savePatentData = []
  for (let i = 0; i < patentData.length; i++) {
    savePatentData.push({
      labNo: labNo,
      patentResultID: patentData[i].patentResultID,
      patentResultTitle: patentData[i].patentResultTitle,
      patentRegistCountry: patentData[i].patentRegistCountry,
      patentRegistNumber: patentData[i].patentRegistNumber,
      patentRegistrant: patentData[i].patentRegistrant,
      patentYear: patentData[i].patentYear,
      patentProjectID: patentData[i].patentProjectID,
      patentRegistType: patentData[i].patentRegistType,
      patentIprType: patentData[i].patentIprType,
      patentProjectYear: patentData[i].patentProjectYear,
      patentBudgetProjectNumber: patentData[i].patentBudgetProjectNumber,
      patentBudgetProject: patentData[i].patentBudgetProject,
      patentMinistryName: patentData[i].patentMinistryName,
      patentProjectTitle: patentData[i].patentProjectTitle,
      patentPerformAgency: patentData[i].patentPerformAgency,
      patentPerformAgent: patentData[i].patentPerformAgent,
      patentSixTechnology: patentData[i].patentSixTechnology,
      patentTechnologyRoadMap: patentData[i].patentTechnologyRoadMap,
      patentScienceClass1: patentData[i].patentScienceClass1,
      patentScienceClass2: patentData[i].patentScienceClass2,
      patentScienceClass3: patentData[i].patentScienceClass3
    })
    const patentDataOpt = { data: savePatentData[i], ...headerOpt }
    const result = await useAxios(patentUrl, patentDataOpt)

    if (result.code === 'ER_DUP_ENTRY' && result.message.includes('board_lab_patent.patentResultID_UNIQUE')) {
      const patent = await getLabPatent({ patentResultID: patentData[i].patentResultID, deletedFlag: 1 })

      if (patent.items instanceof Array) {
        updatePatentTable({ ...patentData[i], deletedFlag: '0' }, patent.items[0].patentId)
      }
    }
  }
}

export function updatePatentTable(patentData, patentId) {
  const url = `${patentUrl}/${patentId}`
  return useAxios(url, {
    method: 'PUT',
    data: patentData
  })
}

// ******************** 산학협력 저장 함수  ********************
export async function insertHandoverTable(handoverData) {
  // console.log(handoverData)
  const data = {
    labNo: handoverData.labNo,
    techStatus: handoverData.techStatus,
    techName: handoverData.techName,
    techNameEn: handoverData.techNameEn,
    techSummary: handoverData.techSummary,
    techSummaryEn: handoverData.techSummaryEn,
    techApplyField: handoverData.techApplyField,
    techApplyFieldEn: handoverData.techApplyFieldEn,
    techTransferType: handoverData.techTransferType,
    techGrowLevel: handoverData.techGrowLevel,
    techTransferYear: handoverData.techTransferYear,
    techTransferResult: handoverData.techTransferResult,
    techTransferResultEn: handoverData.techTransferResultEn,
    techFollowUpSupport: handoverData.techFollowUpSupport,
    techFollowUpSupportEn: handoverData.techFollowUpSupportEn,
    techProgress: handoverData.techProgress,
    techSpecialNote: handoverData.techSpecialNote,
    techSpecialNoteEn: handoverData.techSpecialNoteEn,
    techIndustryCategory: handoverData.techIndustryCategory
  }

  const result = await useAxios(handoverUrl, {
    ...headerOpt,
    data
  })

  return result
}

export async function updateHandoverTable(handoverData, id) {
  // console.log(handoverData)
  const data = {
    labNo: handoverData.labNo,
    techStatus: handoverData.techStatus,
    techName: handoverData.techName,
    techNameEn: handoverData.techNameEn,
    techSummary: handoverData.techSummary,
    techSummaryEn: handoverData.techSummaryEn,
    techApplyField: handoverData.techApplyField,
    techApplyFieldEn: handoverData.techApplyFieldEn,
    techTransferType: handoverData.techTransferType,
    techGrowLevel: handoverData.techGrowLevel,
    techTransferYear: handoverData.techTransferYear,
    techTransferResult: handoverData.techTransferResult,
    techTransferResultEn: handoverData.techTransferResultEn,
    techFollowUpSupport: handoverData.techFollowUpSupport,
    techFollowUpSupportEn: handoverData.techFollowUpSupportEn,
    techProgress: handoverData.techProgress,
    techSpecialNote: handoverData.techSpecialNote,
    techSpecialNoteEn: handoverData.techSpecialNoteEn,
    techIndustryCategory: handoverData.techIndustryCategory
  }

  const result = await useAxios(`${handoverUrl}/${id}`, {
    ...headerOpt,
    method: 'PUT',
    data
  })

  return result
}
