//INFO: 논문게시판 등록
<script>
import { getLabPaper } from '@/api/platform/community'
import LoadingVue from '@/components/ui/Loading.vue'
import { GridComponent, ColumnsDirective, Sort, Toolbar, Search, Page } from '@syncfusion/ej2-vue-grids'
import { getTotalPaperData, searchPaper } from '@/api/admin/ntis/ntis' // 논문 검색
import { insertPaperTable } from '@/api/admin/ntis/ntisSave'

export default {
  name: 'adminBoardPaperCreate',
  components: {
    LoadingVue,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective
  },
  data() {
    return {
      isLoading: false,
      labNo: '',
      registeredKeys: [],

      // ********** 그리드 세팅 **********
      pageSettings: { pageSize: 10 }, // 10개씩 잘라서 페이지네이션
      toolbarOptions: ['Search'], // 툴바에 검색 옵션

      // ********** 검색 **********
      searchedPaperData: [], // 논문 검색 결과
      paperResultData: [], // 논문 검색 결과 데이터
      selectedPaperData: {}, // 논문 검색 결과에서 선택된 데이터
      key: null, // 과제고유번호
      keyword1: '', // 검색어 SRWR
      keyword2: '', // 검색어 SRWR
      keyword3: '', // 검색어 SRWR
      keyword4: '', // 검색어 SRWR
      keyword5: '', // 검색어 SRWR
      select1: 'TI03=', // 검색 필드 searchFd
      select2: '%26TI01=', // 검색 필드 searchFd
      select3: '%26AU01=', // 검색 필드 searchFd
      select4: '%26OG01=', // 검색 필드 searchFd
      select5: '%26PB01=', // 검색 필드 searchFd

      // ********** 사용자 입력 데이터 **********
      userInputData: {}
    }
  },
  provide: {
    grid: [Sort, Toolbar, Search, Page]
  },
  methods: {
    // ********** 목록 버튼 **********
    goPaperList() {
      this.$router.push({
        path: '/admin/board/paper'
      })
    },

    // ********** 논문 검색 버튼 **********
    /**
     * 과제고유번호로 논문 검색
     */
    async paperNumberSearch() {
      if (!this.result_id) {
        alert('검색어를 입력해주세요')
      } else {
        this.isLoading = true
        if (this.paperResultData.TOTALHITS > 0) {
          this.paperResultData = []
        }
        const patentResultID = this.result_id

        const paperData = await searchPaper(patentResultID)
        this.paperResultData.push(paperData)

        this.searchedPaperData = this.paperResultData

        this.isLoading = false
      }
    },

    /**
     * 프로젝트 검색 (논문명, 저자명, ISSN, 학술지명)
     */
    async paperSearchResult() {
      // 검색어, 검색 필드에 값 넣어주기
      // 논문명 설정
      if (this.paperResultTitle) {
        this.keyword2 = this.paperResultTitle
      } else {
        this.keyword2 = ''
      }

      // 저자명 설정
      if (this.paperAuthor) {
        this.keyword3 = this.paperAuthor
      } else {
        this.keyword3 = ''
      }

      // ISSN 번호 설정
      if (this.paperIssnNumber) {
        this.keyword4 = this.paperIssnNumber
      } else {
        this.keyword4 = ''
      }

      // 학술지명 설정
      if (this.paperJournalName) {
        this.keyword5 = this.paperJournalName
      } else {
        this.keyword5 = ''
      }

      this.isLoading = true

      // 검색어 4개 다 없는 경우 alert
      if (!this.keyword2 && !this.keyword3 && !this.keyword4 && !this.keyword5) {
        alert('검색어를 입력해주세요')
      } else {
        // 프로젝트 검색 함수 호출 -> 검색 결과 projectResultData에 담기
        await getTotalPaperData(
          this.keyword1,
          this.select1,
          this.keyword2,
          this.select2,
          this.keyword3,
          this.select3,
          this.keyword4,
          this.select4,
          this.keyword5,
          this.select5
        ).then((gridData) => {
          this.paperResultData = gridData
          this.keyword1 = ''
          this.keyword2 = ''
          this.keyword3 = ''
          this.keyword4 = ''
          this.keyword5 = ''
        })
        this.searchedPaperData = this.paperResultData.LIST
      }
      this.isLoading = false
    },

    // ********** 논문 검색 결과 그리드 행 선택 시 이벤트 **********
    async paperSearchReaultSelected(args) {
      if (this.registeredKeys.includes(args.data.paperResultID)) {
        return alert('이미 등록된 논문입니다.')
      }

      this.isLoading = true
      // 선택된 행의 과제고유번호 key 로 등록
      this.key = args.data.paperResultID
      this.selectedPaperData = await searchPaper(args.data.paperResultID)

      this.isLoading = false
    },

    // ********** 저장 버튼 **********
    async savePaper() {
      // 저장할 논문 데이터
      const savePaperData = []
      savePaperData.push(this.selectedPaperData)

      if (!this.labNo) {
        alert('연구실 번호를 입력해주세요.')
      } else {
        const result = await insertPaperTable(savePaperData, this.labNo, this.paperIssueDate)
        alert('논문이 저장되었습니다.')
        // this.$router.push({ path: '/admin/board/paper' })
        this.goPaperList()
      }
    }
  },
  async mounted() {
    const user = this.$store.state.userStore.info
    if (user.groupName === 'lab') {
      this.labNo = user.labNo
      this.$refs.labNoInput.setAttribute('readonly', true)
    }

    this.registeredKeys = (await getLabPaper())?.items.map((item) => item.paperResultID) ?? []
  }
}
</script>

<template>
  <LoadingVue v-if="isLoading" />
  <!-- 검색 구역 시작 -->
  <div id="paperSearchArea">
    <div class="adminTable">
      <table>
        <colgroup>
          <col width="20%" />
          <col width="80%" />
        </colgroup>
        <tbody>
          <tr>
            <th>연구실 번호</th>
            <td>
              <input type="number" v-model="labNo" name="labNo" ref="labNoInput" />
            </td>
          </tr>
          <tr>
            <th>성과번호</th>
            <td>
              <label class="input-wrapper input_result_id">
                <input type="text" id="" class="" placeholder="성과번호 입력" v-model="result_id" v-on:keyup.enter="paperNumberSearch" />
                <button type="button" id="" @click="paperNumberSearch">
                  <span class="material-icons-outlined"> search </span>
                </button>
              </label>
            </td>
          </tr>
          <tr>
            <th>논문명 / 저자명 / ISSN 번호 / 학술지명</th>
            <td>
              <label class="input-wrapper search_input">
                <input
                  type="text"
                  id=""
                  class=""
                  placeholder="논문명 입력"
                  v-model="paperResultTitle"
                  v-on:keyup.enter="paperSearchResult"
                />
                <input type="text" id="" class="" placeholder="저자명 입력" v-model="paperAuthor" v-on:keyup.enter="paperSearchResult" />
                <input
                  type="text"
                  id=""
                  class=""
                  placeholder="ISSN 번호 입력"
                  v-model="paperIssnNumber"
                  v-on:keyup.enter="paperSearchResult"
                />
                <input
                  type="text"
                  id=""
                  class=""
                  placeholder="학술지명 입력"
                  v-model="paperJournalName"
                  v-on:keyup.enter="paperSearchResult"
                />
                <button type="button" class="paperSearchBtn" @click="paperSearchResult">
                  <span class="material-icons-outlined"> search </span>
                </button>
                <span style="margin-left: 15px; width: 230px">1000건 이하만 검색 가능합니다.</span>
              </label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <br />
  <!-- 검색 구역 끝 -->

  <!-- 검색 결과 구역 시작 -->
  <div class="paperResultArea">
    <!-- 검색 결과 그리드 시작 -->
    <div class="col-lg-12 control-section">
      <ejs-grid
        :dataSource="searchedPaperData"
        :allowSorting="true"
        width="1620"
        :toolbar="toolbarOptions"
        :allowPaging="true"
        :pageSettings="pageSettings"
        :rowSelected="paperSearchReaultSelected"
      >
        <e-columns>
          <e-column field="paperResultID" headerText="성과번호" width="80" :visible="false" textAlign="center"></e-column>
          <e-column field="paperNo" headerText="번호" width="80" textAlign="center"></e-column>
          <e-column field="paperResultTitle" headerText="논문명" :disableHtmlEncode="false" width="300" textAlign="center"></e-column>
          <e-column field="paperJournalName" headerText="학술지명" width="120" :disableHtmlEncode="false" textAlign="center"></e-column>
          <e-column field="paperIssnNumber" headerText="ISSN 번호" width="150" :disableHtmlEncode="false" textAlign="center"></e-column>
          <e-column field="paperAuthor" headerText="저자명" width="180" :disableHtmlEncode="false" textAlign="center"></e-column>
          <e-column field="paperProjectID" headerText="과제고유번호" width="180" :disableHtmlEncode="false" textAlign="center"></e-column>
          <e-column field="paperPubYear" headerText="성과연도" width="180" :disableHtmlEncode="false" textAlign="center"></e-column>
        </e-columns>
      </ejs-grid>
    </div>
    <!-- 검색 결과 그리드 끝 -->

    <!-- <div class="e-statustext" v-if="selectedPaperData.paperProjectID">
      과제고유번호: <b>{{ selectedPaperData.paperProjectID }}</b>
    </div> -->
    <br />

    <!-- 선택된 검색 결과 구역 시작-->
    <div class="selectedPaperResult">
      <span class="info_text">NTIS 정보</span>
      <div class="adminTable">
        <!-- 선택된 검색 결과 수정 불가 정보 시작 -->
        <table>
          <colgroup>
            <col width="10%" />
            <col width="40%" />
            <col width="10%" />
            <col width="40%" />
          </colgroup>
          <tbody>
            <tr>
              <th>논문명</th>
              <td>
                <input type="text" v-model="selectedPaperData.paperResultTitle" disabled size="70" />
              </td>
              <th>키워드(한글)</th>
              <td>
                <input type="text" v-model="selectedPaperData.paperKeyword" disabled size="70" />
              </td>
            </tr>
            <tr>
              <th>학술지명</th>
              <td>
                <input type="text" v-model="selectedPaperData.paperJournalName" disabled size="70" />
              </td>
              <th>키워드(영어)</th>
              <td>
                <input type="text" v-model="selectedPaperData.paperKeywordEn" disabled size="70" />
              </td>
            </tr>
            <tr>
              <th>ISSN 번호</th>
              <td>
                <input type="text" v-model="selectedPaperData.paperIssnNumber" disabled size="70" />
              </td>
              <th>원문여부</th>
              <td>
                <input type="text" v-model="selectedPaperData.paperSourceFlag" disabled size="70" />
              </td>
            </tr>
            <tr>
              <th>저자명</th>
              <td>
                <input type="text" v-model="selectedPaperData.paperAuthor" disabled size="70" />
              </td>
              <th>국내외구분</th>
              <td>
                <input type="text" v-model="selectedPaperData.paperType" disabled size="70" />
              </td>
            </tr>
            <tr>
              <th>과제고유번호</th>
              <td>
                <input type="text" v-model="selectedPaperData.paperProjectID" disabled size="70" />
              </td>
              <th>학술지구분</th>
              <td>
                <input type="text" v-model="selectedPaperData.paperJctType" disabled size="70" />
              </td>
            </tr>
            <tr>
              <th>SCI 구분</th>
              <td>
                <input type="text" v-model="selectedPaperData.paperSciType" disabled size="70" />
              </td>
              <th>성과연도</th>
              <td>
                <input type="text" v-model="selectedPaperData.paperPubYear" disabled />
              </td>
            </tr>
            <tr>
              <th>초록</th>
              <td>
                <textarea type="text" v-model="selectedPaperData.paperAbstract" disabled></textarea>
              </td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <br />
        <!-- 선택된 검색 결과 수정 불가 정보 끝 -->

        <!-- 선택된 검색 결과 입력 정보 시작 -->
        <table>
          <colgroup>
            <col width="10%" />
            <col width="30%" />
            <col width="30%" />
            <col width="30%" />
          </colgroup>
          <tbody>
            <tr>
              <th>발행일</th>
              <td><input type="date" v-model="paperIssueDate" placeholder="발행일" /></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <!-- 선택된 검색 결과 입력 정보 끝 -->
      </div>
    </div>
    <!-- 선택된 검색 결과 구역 끝-->
  </div>
  <!-- 검색 결과 구역 끝 -->

  <!-- 버튼 구역 -->
  <div class="btnArea">
    <button class="e-btn wf__btn btn-primary" @click="goPaperList()">목록</button>
    <button type="submit" id="savePaperBtn" class="e-btn wf__btn btn-success ml-2" @click="savePaper">저장</button>
  </div>
</template>

<style scoped>
.info_text {
  color: #2a7097;
  margin-left: 10px;
  font-size: x-large;
}
#paperSearchArea {
  display: flex; /* 하위 요소 가로 배치 */
  flex-direction: column; /* 배치 방향 설정 */
  flex-wrap: wrap; /* 줄넘김 처리. nowrap: 넘치면 삐져나감. wrap: 줄넘김 */
  align-content: stretch; /* 아이템들의 행이 2줄 이상 되었을 때 수직축 방향 결정*/
  justify-content: flex-start; /* 메인축 방향 정렬 */
  align-items: flex-start; /* 수직축 방향 정렬 */
}
.selectedPaperReault textarea {
  width: 540px;
  height: 100px;
}
/* 연구실 번호 */
#labNoArea {
  margin: 10px 10px;
}
#labNoArea > input:nth-child(1) {
  width: 150px;
  border: 2px solid #e9f2f9;
  padding: 0.5em 1em;
  background-color: #e9f2f9;
}
#labNoArea > input:nth-child(2) {
  width: 200px;
  border: 2px solid var(--wf-primary-color);
  padding: 0.5em 1em;
  margin-left: 10px;
}
/* 검색 구역 */
.input-wrapper {
  display: flex;
}
.input-wrapper input {
  width: 20em;
  border: 2px solid var(--wf-primary-color);
  padding: 0.5em 1em;
}
.input-wrapper input::placeholder {
  color: var(--wf-gray-color);
}
.input-wrapper button {
  background-color: var(--wf-primary-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  padding: 0.3em;
}
.input-wrapper button span {
  color: #fff;
  font-size: 28px;
}
.paperSearchBtn {
  background-color: var(--wf-primary-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  padding: 0.3em;
}
.paperSearchBtn span {
  color: #fff;
  font-size: 28px;
}
.search_input > input {
  margin-right: 10px;
}

.btnArea {
  padding-top: 30px;
  margin: 0 auto;
}
</style>
